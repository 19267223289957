import { Components, Theme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const components = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: "#F9FAFA",
        color: "#333333",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
      sizeMedium: {
        padding: "10px 18px",
      },
    },
    variants: [
      {
        props: {
          variant: "default",
        },
        style: {
          backgroundColor: "#ffffff",
          border: "solid 1px #F4F5F5",
          color: theme.palette.primary.main,
        },
      },
      {
        props: {
          variant: "default",
          size: "medium",
        },
        style: {
          padding: "9px 18px",
        },
      },
    ],
  },
  MuiSlider: {
    styleOverrides: {},
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        backgroundImage: "none",
        border: "solid 1px #F4F5F5",
        boxShadow: "none",
        // boxShadow:
        //   "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {},
    },
    defaultProps: {
      sx: {
        p: {
          lg: theme.spacing(3),
          sm: theme.spacing(2.5),
          xs: theme.spacing(2),
        },
      },
    },
  },
  MuiModal: {
    defaultProps: {
      BackdropProps: {
        sx: {
          backgroundColor: "rgba(0,0,0,0.08)",
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      formControl: {},
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: "outlined",
      size: "medium",
    },
    styleOverrides: {
      root: {
        "&": {
          backgroundColor: "transparent",
        },
      },
    },
    variants: [
      {
        props: {
          variant: "outlined",
          size: "medium",
        },
        style: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
          "& .MuiOutlinedInput-input": {
            paddingTop: "12px",
            paddingBottom: "12px",
          },
          "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            transform: "translate(14px, 12px) scale(1)",
          },
        },
      },
      {
        props: {
          variant: "outlined",
        },
        style: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#ffffff",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.line.default,
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[200],
          },
        },
      },
    ],
  },
  MuiTextField: {
    defaultProps: {
      variant: "outlined",
    },
  },
  MuiOutlinedInput: {
    variants: [
      {
        props: {
          size: "medium",
          multiline: true,
        },
        style: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    ],
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: KeyboardArrowDownIcon,
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        sectionTitle: "div",
        sectionDesc: "div",
      },
    },
  },
  MuiTable: {},
  MuiChip: {
    variants: [
      {
        props: {
          variant: "filled",
          size: "small",
        },
        style: {
          borderRadius: "4px",
          fontWeight: 600,
          fontSize: 12,
          height: 24,
          "& .MuiChip-label": {
            paddingLeft: 12,
            paddingRight: 12,
          },
        },
      },
    ],
  },
  MuiPaginationItem: {
    variants: [
      {
        props: {
          variant: "outlined",
          shape: "rounded",
        },
        style: {
          borderColor: theme.line.default,
        },
      },
    ],
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: theme.line.default,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#F1F6F6",
          fontWeight: 600,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      grouped: {
        borderColor: "#F4F5F5",
        paddingLeft: "16px",
        paddingRight: "16px",
        borderRadius: "8px",
      },
    },
  },
});

export default components;

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    default: true;
  }
}
